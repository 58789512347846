<template>
    <div class="panel panel-default ">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.startDate.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.endDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" >
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: { DatePicker },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                }
            }
        },
    }
</script>

<style scoped>

</style>