<template>
    <div>
        <h1>Stock Movement</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './stock_movement_opt.vue'
    import Results from "./utils/Results";

    export default {
        name: "stock_movement",
        components: {
            Options,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: new Date(new Date().setDate(1)).toLocaleDateString(), qsField: '[datetimes][transaction_date_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[datetimes][transaction_date_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][it.cdi_location_id]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Stock Movement']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>